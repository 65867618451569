import React from 'react';
import List from '@material-ui/core/List';

import routesConfig from '../../../../modules/routesConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
import {
  selectUseNevro,
  selectUseMainstay,
  selectUseCodePull,
  selectIsPortalProClinic,
} from 'redux/reducers/admin/instance-settings';
import { useDispatch, useSelector } from 'react-redux';
const Navigation = () => {
  const useNevro = useSelector(selectUseNevro);
  const useMainstay = useSelector(selectUseMainstay);
  const useCodePull = useSelector(selectUseCodePull);
  const isPortalProClinic = useSelector(selectIsPortalProClinic);
  let isParent = false;
  let isChild = false;
  if (localStorage.getItem('isParent')) {
    isParent = localStorage.getItem('isParent') === 'true';
  }
  if (localStorage.getItem('isChild')) {
    isChild = localStorage.getItem('isChild') === 'true';
  }
  let userRole = localStorage.getItem('userRole');
  let isSystemManager = userRole.indexOf('System Manager') > -1;

  let isHFXSite = isParent;
  if (localStorage.getItem('clinicId')) {
    isHFXSite = localStorage.getItem('clinicId') === '';
  }

  const canRenderThisItem = (item) => {
    if (!useNevro) {
      if (item.hideOnNevroNotEnabled !== undefined) {
        if (item.hideOnNevroNotEnabled) {
          return false;
        }
      }
    }
    if (
      isPortalProClinic &&
      item.id === 'patientlist' &&
      item.portalpro !== undefined &&
      !item.portalpro
    )
      return false;

    if (
      useCodePull &&
      (item.id === 'patientcampaigns' || item.id === 'contactsupport')
    ) {
      if (item.id === 'contactsupport') {
        return true;
      }

      if (useNevro && isHFXSite) {
        return false;
      }
      // else if (
      //   userRole.toLowerCase() === 'staff'
      // ) {
      //   return false;
      // } 
      else {
        return true;
      }
    } else if (
      !useCodePull &&
      (item.id == 'patientcampaigns' || item.id === 'contactsupport')
    ) {
      return false;
    }

    if (isSystemManager && item.hideOnNevroSystemManager) {
      return false;
    }

    if (isSystemManager) {
      return true;
    }

    if (isParent) {
      if (useMainstay) {
        if (item.mainstayParent) {
          return true;
        } else {
          return false;
        }
      }

      if (item.isParent !== undefined) {
        if (item.isParent === isParent && isParent === true) {
          return true;
        }

        return false;
      }
    }

    if (isChild) {
      if (useMainstay) {
        if (item.mainstayChild) {
          return item.access.indexOf(userRole) > -1;
        } else {
          return false;
        }
      }
    }

    if (useNevro) {
      if (item.nevro === undefined) {
        return true;
      }

      return item.nevro === useNevro;
    }

    if (item.access.indexOf(userRole) > -1) {
      return true;
    }

    return false;
  };

  return (
    <List
    // style={{ paddingBottom: 140 }}
    >
      {routesConfig.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && canRenderThisItem(item) && (
            <VerticalNavGroup item={item} level={0} />
          )}

          {item.type === 'collapse' && canRenderThisItem(item) && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === 'item' && canRenderThisItem(item) && (
            <VerticalItem item={item} level={0} />
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default Navigation;
