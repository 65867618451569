import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box, Card, makeStyles} from '@material-ui/core';

const Loader = () => {
  const useStyles = makeStyles(() => ({
    cardRoot: {
      boxShadow: '10px 10px 135px 0px rgba(0,0,0,0.75) !important',
      padding: '1rem 2.5rem',
      borderRadius: '10px',
    },
  }));
  const classes = useStyles();

  return (
    <Box
      height='100%'
      display='flex'
      flex={1}
      alignItems='center'
      justifyContent='center'
      position='absolute'
      top={0}
      left={0}
      right={0}
      bottom={0}>
      <Card className={classes.cardRoot}>
        <CircularProgress />
      </Card>
    </Box>
  );
};

export default Loader;
