import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import LanguageSwitcher from '../../LanguageSwitcher';
import {
  setNotificationCount,
  toggleNavCollapsed,
} from '../../../../redux/actions';
import {connect, useDispatch, useSelector} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import SearchBar from '../../SearchBar';
import useStyles from './AppHeader.style';
import UserInfo from '../../../../shared/components/UserInfo';
import clsx from 'clsx';
import Notifications from '../../../../shared/components/Notifications';
import DoctorSwitcher from '../../../../shared/components/DoctorSwitcher';
import GooglePageTranslator from '../../../../shared/components/GooglePageTranslator';
import {useAuthUser} from '@e74/utility/AppHooks';
import ClinicSwitcher from 'shared/components/ClinicSwitcher';
import {selectUseNevro} from 'redux/reducers/admin/instance-settings';
import {useLocation, useHistory} from 'react-router-dom';
import ApiConfig from '@e74/services/ApiConfig';
import {NOTIFICATION_POLLING_INTERVAL_SECONDS} from 'shared/constants/AppConst';
import {selectNotificationCount} from 'redux/reducers/notification';
import * as actions from 'redux/actions/notification';
import Badge from '@material-ui/core/Badge';

const mapActionToProps = {
  setNotificationCount: actions.setNotificationCount,
};

const AppHeaderNevro = ({...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const user = useAuthUser();
  const useNevro = useSelector(selectUseNevro);
  let userRole = localStorage.getItem('userRole');
  let isSystemManager = userRole.indexOf('System Manager') > -1;
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState('');
  const history = useHistory();

  const notificationCount = useSelector(selectNotificationCount);
  let isParent = false;
  if (localStorage.getItem('isParent')) {
    isParent = localStorage.getItem('isParent') === 'true';
  }

  let isHFXSite = isParent;
  const isNevroStaff =
    (userRole?.toLowerCase() === 'staff' ||
      userRole?.toLowerCase() === 'staff') &&
    isHFXSite;
  if (localStorage.getItem('clinicId')) {
    isHFXSite = localStorage.getItem('clinicId') === '';
  }
  useEffect(() => {
    startNotificationPolling();
  }, [useNevro]);

  const findCurrentLocation = () => {
    switch (location.pathname) {
      case '/patients/list':
        setCurrentLocation('patient');
        break;
      case '/users':
        setCurrentLocation('user');
        break;
      case '/clinics':
        setCurrentLocation('clinics');
        break;
      default:
        setCurrentLocation('');
        break;
    }
  };

  useEffect(() => {
    findCurrentLocation();
  }, [location]);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  const handleAddButtonClick = () => {
    switch (currentLocation) {
      case 'patient':
        history.push('/patients/basicintakenevro');
        break;
      case 'user':
        history.push('/users/userform');
        break;
      case 'clinics':
        history.push('/clinics/clinicform');
        break;
      default:
        break;
    }
  };

  const startNotificationPolling = () => {
    if (useNevro) {
      ApiConfig.nevro()
        .getChatterNotificationCount()
        .then((response) => {
          props.setNotificationCount(response.data);
          setTimeout(
            startNotificationPolling,
            NOTIFICATION_POLLING_INTERVAL_SECONDS * 1000,
          );
        })
        .catch((error) => {
          setTimeout(
            startNotificationPolling,
            NOTIFICATION_POLLING_INTERVAL_SECONDS * 1000,
          );
        });
    }
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}></Menu>
  );

  return (
    <>
      <AppBar color='inherit' className={clsx(classes.appBar, 'app-bar')}>
        <Toolbar className={classes.appToolbar}>
          <Hidden lgUp>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='open drawer'
              onClick={() => dispatch(toggleNavCollapsed())}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>

          <Box className={classes.grow} />

          {/* <UserInfo /> */}

          {/* <Box marginX={4} className={classes.sectionDesktop}>
            // <LanguageSwitcher />
            <GooglePageTranslator />
          </Box> */}

          {/* {useNevro && (user.isParent || isSystemManager) ? (
            <ClinicSwitcher />
          ) : (
            <DoctorSwitcher />
          )} */}

          {/* {!user.isParent && (
            <DoctorSwitcher />
          )} */}

          <IconButton
            edge='start'
            color={'inherit'}
            onClick={() => history.push('/patients/nevro/chatternotification')}>
            <Badge
              badgeContent={notificationCount > 0 ? notificationCount : 0}
              color='secondary'>
              <NotificationsActiveIcon />
            </Badge>
          </IconButton>

          <SearchBar borderLight placeholder={` Search Patients...`} />

          {currentLocation !== '' &&
            userRole !== 'Staff' &&
            userRole !== 'Doctor' &&
            (isHFXSite === false ||
              (isHFXSite === true && currentLocation !== 'patient')) && (
              <Button
                variant='contained'
                onClick={handleAddButtonClick}
                endIcon={<AddCircleOutlineIcon />}
                style={{
                  marginLeft: '0.25rem',
                  backgroundColor: '#00B6EE',
                  color: 'white',
                  fontWeight: 500,
                }}>
                Add New {currentLocation}
              </Button>
            )}
          {currentLocation !== '' &&
            (userRole == 'Staff' || userRole == 'Doctor') &&
            currentLocation === 'patient' &&
            (isParent === false ||
              (isParent === true &&
                currentLocation === 'patient' &&
                userRole !== 'Staff')) && (
              <Button
                variant='contained'
                onClick={handleAddButtonClick}
                endIcon={<AddCircleOutlineIcon />}
                style={{
                  marginLeft: '0.25rem',
                  backgroundColor: '#00B6EE',
                  color: 'white',
                  fontWeight: 500,
                }}>
                Add New {currentLocation}
              </Button>
            )}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};

export default connect(null, mapActionToProps)(AppHeaderNevro);
