const routesConfig = [
  {
    id: 'admin',
    title: 'System Configuration',
    messageId: 'sidebar.admin',
    type: 'collapse',
    access: 'Admin, System Manager',
    nevro: false,
    isParent: false,
    mainstayParent: false,
    mainstayChild: false,
    icon: 'tune',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        messageId: 'sidebar.admin.dashboard',
        type: 'item',
        url: '/admin/dashboard',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
      },
      {
        id: 'bodylocations',
        title: 'PT Locations',
        messageId: 'sidebar.admin.bodylocations',
        type: 'item',
        url: '/admin/bodylocations',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'medicines',
        title: 'Medicines',
        messageId: 'sidebar.admin.medicines',
        type: 'item',
        url: '/admin/medicines',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'studygroups',
        title: 'Cohorts',
        messageId: 'sidebar.admin.studygroups',
        type: 'item',
        url: '/admin/studygroups',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'rooms',
        title: 'Rooms',
        messageId: 'sidebar.admin.rooms',
        type: 'item',
        url: '/admin/rooms',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'interventionallocations',
        title: 'Interventional Locations',
        messageId: 'sidebar.admin.interventionallocations',
        type: 'item',
        url: '/admin/interventionallocations',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'adainterventionallocations',
        title: 'Ada Interventional Locations',
        messageId: 'sidebar.admin.adainterventionallocations',
        type: 'item',
        url: '/admin/adainterventionallocations',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'interventionaldirections',
        title: 'Interventional Directions',
        messageId: 'sidebar.admin.interventionaldirections',
        type: 'item',
        url: '/admin/interventionaldirections',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'interventionalprocedures',
        title: 'Interventional Procedures',
        messageId: 'sidebar.admin.interventionalprocedures',
        type: 'item',
        url: '/admin/interventionalitems/1',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'injections',
        title: 'Injections',
        messageId: 'sidebar.admin.injections',
        type: 'item',
        url: '/admin/interventionalitems/2',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'bodyparts',
        title: 'Body Parts',
        messageId: 'sidebar.admin.bodyparts',
        type: 'item',
        url: '/admin/interventionalitems/3',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'referrals',
        title: 'Referrals',
        messageId: 'sidebar.admin.referrals',
        type: 'item',
        url: '/admin/referrals',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'painpumpmedicines',
        title: 'Pain Pump Medicines',
        messageId: 'sidebar.admin.painpumpmedicines',
        type: 'item',
        url: '/admin/painpumpmedicines',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'resourcetypes',
        title: 'Resource Types',
        messageId: 'sidebar.admin.resourcetypes',
        type: 'item',
        url: '/admin/resourcetypes',
        access: 'Admin, System Manager',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
    ],
  },
  {
    id: 'pathways',
    title: 'Pathways',
    messageId: 'sidebar.admin.pathways',
    type: 'item',
    url: '/pathways',
    access: 'System Manager, Admin, Doctor, Staff',
    nevro: false,
    isParent: false,
    mainstayParent: false,
    mainstayChild: false,
    icon: 'sms',
  },
  {
    id: 'communicationdashboard',
    title: 'Communication Dashboard',
    messageId: 'sidebar.communicationdashboard',
    type: 'item',
    url: '/communicationdashboard',
    access: 'System Manager, Admin',
    nevro: false,
    isParent: false,
    mainstayParent: false,
    mainstayChild: false,
    icon: 'mail',
  },
  {
    id: 'patientcampaigns',
    title: 'Code Pulls',
    messageId: 'sidebar.codepulls',
    type: 'item',
    url: '/patientcampaigns/dashboard',
    access: 'System Manager, Admin, Staff',
    icon: 'assessment',
    // nevro: true,
    // hideOnNevroNotEnabled: true,
    // hideOnNevroSystemManager: true,
  },
  {
    id: 'patients',
    title: 'Patients',
    messageId: 'sidebar.patients',
    type: 'collapse',
    icon: 'emojiPeopleOutlined',
    access: 'System Manager, Admin, Doctor, Staff',
    nevro: false,
    isParent: false,
    mainstayParent: true,
    mainstayChild: true,
    children: [
      /*{
        id: 'dashboard',
        title: 'Dashboard',
        messageId: 'sidebar.patients.dashboard',
        type: 'item',
        url: '/patients/dashboard',
        access: 'System Manager, Admin, Doctor, Staff',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'newpatient',
        title: 'New Patient',
        messageId: 'sidebar.patients.newpatient',
        type: 'item',
        url: '/patients/basicintake',
        access: 'System Manager, Admin, Doctor, Staff',
        hideOnNevroNotEnabled: true,
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },*/
      {
        id: 'patientlist',
        title: 'Patients',
        messageId: 'sidebar.patients.patientlist',
        type: 'item',
        url: '/patients/list',
        access: 'System Manager, Admin, Doctor, Staff',
        isParent: true,
        mainstayParent: true,
        mainstayChild: true,
        nevro: true,
      },
      {
        id: 'appointmentlist',
        title: 'Appointments Dashboard',
        messageId: 'sidebar.patients.appointmentlist',
        type: 'item',
        url: '/patients/appointments',
        access: 'System Manager, Admin, Doctor, Staff',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
    ],
  },
  {
    id: 'patientlist',
    title: 'Patients',
    type: 'item',
    messageId: 'sidebar.patientlist',
    access: 'System Manager, Admin, Doctor, Staff',
    nevro: true,
    portalpro: false,
    hideOnNevroNotEnabled: true,
    hideOnNevroSystemManager: true,
    icon: 'emojiPeopleOutlined',
    url: '/patients/list',
  },
  {
    id: 'reports',
    title: 'Reports',
    messageId: 'sidebar.reports',
    type: 'collapse',
    icon: 'assessment',
    access: 'System Manager, Admin, Doctor',
    nevro: false,
    isParent: false,
    mainstayParent: false,
    mainstayChild: false,
    children: [
      {
        id: 'summary',
        title: 'Data Sandbox',
        messageId: 'sidebar.reports.summary',
        type: 'item',
        url: '/reports/summary',
        access: 'System Manager, Admin, Doctor',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'patientsspotlight',
        title: 'Patients Spotlight',
        messageId: 'sidebar.reports.patientsspotlight',
        type: 'item',
        url: '/reports/patientsspotlight',
        access: 'System Manager, Admin, Doctor',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'surveyreport',
        title: 'Survey Report',
        messageId: 'sidebar.reports.surveyreport',
        type: 'item',
        url: '/reports/surveyreport',
        access: 'System Manager, Admin, Doctor',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'patientsperformancemeasure',
        title: 'Performance Measure',
        messageId: 'sidebar.reports.patientsperformancemeasure',
        type: 'item',
        url: '/reports/patientsperformancemeasure',
        access: 'System Manager, Admin, Doctor',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'refferaltracker',
        title: 'Referral Tracker',
        messageId: 'sidebar.reports.refferaltracker',
        type: 'item',
        url: '/reports/refferaltracker',
        access: 'System Manager, Admin, Doctor',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'officevisits',
        title: 'Office Visits',
        messageId: 'sidebar.reports.officevisits',
        type: 'item',
        url: '/reports/officevisits',
        access: 'System Manager, Admin, Doctor',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'providerappointmentdashboard',
        title: 'Provider Appointment Dashboard',
        messageId: 'sidebar.reports.providerappointmentdashboard',
        type: 'item',
        url: '/reports/providerbaselines',
        access: 'System Manager, Admin, Doctor',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
    ],
  },
  {
    id: 'users',
    title: 'Users',
    type: 'item',
    messageId: 'sidebar.users',
    access: 'System Manager, Admin, Doctor',
    nevro: true,
    isParent: true,
    mainstayParent: true,
    mainstayChild: true,
    icon: 'people',
    url: '/users',
  },
  {
    id: 'contactsupport',
    title: 'Contact Support',
    type: 'item',
    messageId: 'sidebar.contactsupport',
    access: 'System Manager, Admin, Doctor, Staff',
    nevro: false,
    portalpro: true,
    hideOnNevroNotEnabled: false,
    hideOnNevroSystemManager: true,
    icon: 'HeadsetMic',
    url: '/contactsupport',
  },
  {
    id: 'instancesettings',
    title: 'Instance Settings',
    messageId: 'sidebar.admin.instancesettings',
    type: 'item',
    icon: 'tune',
    url: '/admin/instancesettings',
    access: 'System Manager',
    nevro: false,
    isParent: false,
    mainstayParent: false,
    mainstayChild: false,
  },
  {
    id: 'logindetail',
    title: 'Login Details',
    type: 'item',
    messageId: 'sidebar.logindetails',
    access: 'System Manager, Admin',
    nevro: false,
    isParent: false,
    mainstayParent: false,
    mainstayChild: false,
    icon: 'tune',
    url: '/logindetails',
  },
  {
    id: 'activitylog',
    title: 'Activity Logs',
    type: 'item',
    messageId: 'sidebar.activitylogs',
    access: 'System Manager, Admin',
    nevro: false,
    isParent: false,
    mainstayParent: false,
    mainstayChild: false,
    icon: 'tune',
    url: '/activitylogs',
  },
  {
    id: 'instances',
    title: 'Instances',
    type: 'item',
    messageId: 'sidebar.instances',
    access: 'Admin, System Manager',
    nevro: false,
    isParent: false,
    mainstayParent: false,
    mainstayChild: false,
    icon: 'tune',
    url: '/instances',
  },
  {
    id: 'clinics',
    title: 'Clinics',
    type: 'item',
    messageId: 'sidebar.clinics',
    access: 'Admin, System Manager',
    nevro: false,
    isParent: true,
    mainstayParent: true,
    mainstayChild: false,
    icon: 'homeWorkOutlined',
    url: '/clinics',
  },
  {
    id: 'resources',
    title: 'Media Center',
    messageId: 'sidebar.resources',
    type: 'collapse',
    icon: 'video_library',
    access: 'System Manager, Admin, Staff, Doctor',
    nevro: false,
    isParent: false,
    mainstayParent: false,
    mainstayChild: false,
    children: [
      {
        id: 'resourcelist',
        title: 'List',
        type: 'item',
        messageId: 'sidebar.resources.resourcelist',
        url: '/resources/list',
        icon: 'video_library',
        access: 'System Manager, Admin, Staff, Doctor',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'resourceactivity',
        title: 'Activity',
        type: 'item',
        messageId: 'sidebar.resources.resourceactivity',
        url: '/resources/activity',
        icon: 'visibility',
        access: 'System Manager, Admin, Staff, Doctor',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
      {
        id: 'faq',
        title: 'FAQ',
        type: 'item',
        messageId: 'sidebar.resources.faq',
        url: '/resources/faq',
        icon: 'quiz',
        access: 'System Manager, Admin, Staff, Doctor',
        nevro: false,
        isParent: false,
        mainstayParent: false,
        mainstayChild: false,
      },
    ],
  },
  {
    id: 'usermessages',
    title: 'Messages',
    type: 'item',
    messageId: 'sidebar.usermessages',
    icon: 'chat',
    url: '/user-messages',
    access: 'System Manager, Admin, Doctor',
    nevro: false,
    isParent: false,
    mainstayParent: false,
    mainstayChild: false,
  },
];
export default routesConfig;
