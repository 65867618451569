import {lazy} from 'react';

export const codePullPagesConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/patientcampaigns/dashboard',
        component: lazy(() => import('./campaigns')),
      },
      {
        path: '/codepulls/templates',
        component: lazy(() => import('./templates')),
      },
      {
        path: '/patientcampaigns/newcampaign',
        component: lazy(() => import('./campaignForm')),
      },
      {
        path: '/patientcampaigns/:id',
        component: lazy(() => import('./campaignPatients')),
      },
      {
        path: '/approvedcampaign/:id',
        component: lazy(() => import('./approvedCampaign')),
      },
      {
        path: '/editcampaign/:id',
        component: lazy(() => import('./approvedCampaign')),
      },
      {
        path: '/campaignResponse/:id',
        component: lazy(() => import('./campaignPatientResponse')),
      },
      {
        path: '/templateList',
        component: lazy(() => import('./templateList')),
      },
      {
        path: '/contactsupport',
        component: lazy(() => import('./contactSupport')),
      },
    ],
  },
];
