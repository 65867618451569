import React, {useContext, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';

import AppContext from '../../utility/AppContext';
import Layouts from './Layouts';
import {ContentView} from '../../index';
import BG from '../../../assets/images/auth-background.jpg';
import useStyles from '../../../shared/jss/common/common.style';
import {
  IsPortalProClinic,
  fetchEnablePortalPro,
  fetchTimeZoneOffsetHours,
  fetchUseCodePull,
} from 'redux/actions/admin/instance-settings';
import {fetchUseNevro} from 'redux/actions/admin/instance-settings';
import {
  selectEnablePortalPro,
  selectIsPortalProClinic,
  selectUseNevro,
} from 'redux/reducers/admin/instance-settings';
import BGNevro from '../../../assets/images/NevroLoginScreen.jpg';
import ApiConfig from '@e74/services/ApiConfig';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';

const useStyle = makeStyles((theme) => ({
  appAuth: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    backgroundColor: '#f3f4f6',
    background: `url(${BG}) no-repeat center bottom`,
    backgroundSize: 'cover',

    '& .scrollbar-container': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto !important',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },

    '& .main-content-view': {
      padding: 20,
    },
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  appAuthNevro: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    backgroundColor: '#fff',
    background: `url(${BGNevro}) no-repeat center bottom`,
    backgroundSize: 'cover',
    '& .scrollbar-container': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto !important',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },

    '& .main-content-view': {
      padding: 20,
    },
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));

const E74Layout = ({children}) => {
  useStyles();
  const {navStyle} = useContext(AppContext);
  const authUser = useSelector(({auth}) => auth.user);
  const useNevro = useSelector(selectUseNevro);
  const isPortalProClinic = useSelector(selectIsPortalProClinic);
  const AppLayout = Layouts[navStyle];
  const dispatch = useDispatch();
  const location = useLocation();

  const [showNevroTheme, setShowNevroTheme] = useState(false);

  useEffect(() => {
    dispatch(fetchUseNevro());
    dispatch(fetchUseCodePull());
    dispatch(IsPortalProClinic());
  }, []);

  useEffect(() => {
    if (authUser) {
      dispatch(fetchTimeZoneOffsetHours());
    }
  }, [authUser]);

  useEffect(() => {
    const path = location.pathname.toLowerCase();
    switch (true) {
      case path.startsWith('/nevro/doctor/consent'):
      case path.startsWith('/patients/nevro/intake/patient'):
      case path.startsWith('/patients/nevro/intake/doctor'):
        setShowNevroTheme(true);
        break;
      default:
        break;
    }
  }, [location.pathname]);

  const classes = useStyle();
  return (
    <>
      {authUser && <AppLayout>{children}</AppLayout>}
      {!authUser && useNevro && !isPortalProClinic && (
        <Box className={classes.appAuthNevro}>
          <ContentView />
        </Box>
      )}
      {!authUser &&
        (isPortalProClinic || (useNevro !== undefined && !useNevro)) && (
          <Box
            className={showNevroTheme ? classes.appAuthNevro : classes.appAuth}>
            <ContentView />
          </Box>
        )}
    </>
  );
};

export default React.memo(E74Layout);
